import {
  CaptionTitle,
  PriceBreakdown,
  ProductTile,
  ProductTileLarge,
  ProductTileItem,
  RBadge,
  RPrice,
} from "@ritual/essentials-for-react";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import cartService from "../../services/cart";
import { addProductToCart } from "../../store/cart/actions";
import { getStore } from "../../store/createStore";
import {
  allProductOffersByCurrentStore,
  productOfferForId,
} from "../../store/product-offer/selectors";
import { getFeaturedBundleName } from "../../utils/bundle";
import metrics from "../../utils/metrics";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import Currency from "../Currency";
import DiscountButton from "../bundle/DiscountButton";

const BundleProductImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
`;

const IncludedImage = styled(GatsbyImage)`
  width: 64px;
  height: 64px;
`;

type Flag = {
  content: string | null;
};

type Product = {
  image: {
    gatsbyImageData: any;
    description: string;
  };
  name: {
    name: string;
  };
  shortDescription: string;
};

type Content = {
  title: string;
  slug: string;
  sku: string | null;
  featuredImage: {
    gatsbyImageData: any;
    description: string;
  };
  shortDescription: string;
  ritualProductOffer: {
    product_offer_id: string;
  };
  products: Product[];
};

type Item = {
  size: string;
  content: Content;
  tileFlag: Flag;
  titleFlag: Flag | null;
};

type ItemClickAction = (item?: Item) => void;

type BundleProductTileProps = <T>(props: {
  item: Item;
  onItemClick: ItemClickAction;
}) => JSX.Element | null;

function productOfferInCurrentStore(productOfferRedux: { id: string }) {
  const allProductOffersForStore = useSelector(allProductOffersByCurrentStore);
  return allProductOffersForStore.find(
    (productOffer: { id: string }) => productOfferRedux.id === productOffer.id,
  );
}

function getFuturePrice(productOfferRedux: { futureAmount: any }) {
  if (!productOfferRedux) return 0;

  return productOfferRedux.futureAmount(getStore().getState()) / 100;
}

const BundleProductTile: BundleProductTileProps = ({ item, onItemClick }) => {
  const { size, content, tileFlag } = item;

  const {
    ritualProductOffer,
    featuredImage: image,
    title: productName,
    shortDescription,
    products,
    slug,
  } = content;

  const dispatch = useDispatch();
  const productOfferRedux = useSelector((state) =>
    productOfferForId(state, ritualProductOffer?.product_offer_id),
  );

  if (productOfferRedux && !productOfferInCurrentStore(productOfferRedux)) {
    console.warn(
      "Missing: Product Offer in current store on BundleProductTile",
    );
    return null;
  }

  const price = productOfferRedux.initialAmount(getStore().getState()) / 100;
  const futurePrice = getFuturePrice(productOfferRedux);
  const savings = Math.floor(((futurePrice - price) * 100) / 100).toFixed(0);
  const pricing = { price, futurePrice, savings };

  const handleAddBundle = (e: any) => {
    metrics.track("CTA Clicked", {
      location: "Product List Page Bundle Cards",
      title: e?.target?.innerText,
      name: getFeaturedBundleName(item.content),
    });

    dispatch(
      addProductToCart(
        {
          productOfferId: ritualProductOffer.product_offer_id,
        },
        {
          location: "Product List Page Bundle Cards",
        },
      ),
    );

    cartService.openCart(e);
  };

  const discountButtonProps = {
    to: "/cart",
    noNavigate: true,
    onClick: handleAddBundle,
    price: undefined,
    prefix: "Add",
    className: "plp-cta fullwidth",
    showDash: false,
  };

  const handleCardClick = (e: any) => {
    const target = e?.target;
    const containsAdd = target?.textContent?.includes("Add");

    if (!containsAdd) {
      metrics.track("Product Clicked", {
        location: "Product List Page Bundle Cards",
        name: getFeaturedBundleName(item.content),
        title: "Quick View",
        products: products.map((p: any) => getPropertiesForProductSku(p.sku)),
      });
      onItemClick({ ...item, ...pricing });
    }
  };

  const imageData: IGatsbyImageData | undefined =
    image && getImage(image.gatsbyImageData);

  const isLargeTile = size === "Large";
  const TileComponent = isLargeTile ? ProductTileLarge : ProductTile;
  const tileProps = isLargeTile
    ? {
        name: productName,
        handleClick: handleCardClick,
      }
    : {
        name: productName,
        description: shortDescription,
        handleClick: handleCardClick,
        isBaseTile: false,
        size: size?.toLowerCase(),
        isFeaturedProduct: false,
      };

  return (
    <TileComponent {...tileProps}>
      {imageData && (
        <span slot="product-image">
          <GatsbyImage
            image={imageData}
            alt={image.description}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </span>
      )}
      {tileFlag && (
        <RBadge
          slot="product-image-r-badge"
          badgeColor="yellow"
          badgeWidth="wide"
        >
          {tileFlag.content}
        </RBadge>
      )}
      <div slot="product-image-cta">
        <DiscountButton {...discountButtonProps} />
      </div>

      <PriceBreakdown slot="price-breakdown" spread={true}>
        <RPrice slot="price-breakdown-final" priceClass="final">
          {/* @ts-ignore */}
          <Currency value={price} round={true} />
        </RPrice>
        {futurePrice && (
          <RPrice
            slot="price-breakdown-strikethrough"
            priceClass="strikethrough"
          >
            {/* @ts-ignore */}
            <Currency value={futurePrice} round={true} />
          </RPrice>
        )}
        {savings && (
          <CaptionTitle slot="price-breakdown-savings-caption">
            {/* @ts-ignore */}
            <Currency value={savings} round={true} /> savings
          </CaptionTitle>
        )}
      </PriceBreakdown>

      {size === "Large" &&
        products.map((product, i) => (
          <ProductTileItem
            slot="bundle-product-item"
            productTitle={product.name.name}
            productDescription={product.shortDescription}
            key={i}
          >
            <BundleProductImage slot="product-tile-item-image">
              {product?.image && (
                <IncludedImage
                  image={
                    getImage(
                      product?.image?.gatsbyImageData,
                    ) as IGatsbyImageData
                  }
                  alt={product?.image?.description}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              )}
            </BundleProductImage>
          </ProductTileItem>
        ))}
    </TileComponent>
  );
};

export default BundleProductTile;

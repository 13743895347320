import muxMonitor from "./muxMonitor";

class Hls {
  /**
   * If a browser does not natively support HTTP Live Streaming,
   * we will polyfill playback using hls.js, which uses
   * MediaSource Extensions in a Web Worker for playback.
   * https://www.npmjs.com/package/hls.js
   */

  polyfill = null;

  activate = async (src, ref, metadata = {}) => {
    muxMonitor.init();

    if (ref) {
      await this.loadPolyfill();
      if (this.polyfill?.isSupported()) {
        const hls = new this.polyfill();
        hls.loadSource(src);
        hls.attachMedia(ref);

        metadata = {
          ...metadata,
          hls,
          Hls: this.polyfill,
        };
      } else if (ref.canPlayType("application/vnd.apple.mpegurl")) {
        ref.src = src;
      }
    }

    muxMonitor.pushEvent({
      ...metadata,
      src,
      ref,
    });
  };

  async loadPolyfill() {
    if (this.polyfill) return;

    try {
      const chunk = await import("hls.js");
      this.polyfill = chunk.default;
    } catch (e) {
      console.error(
        "Failed to load hls.js. Video playback may be interrupted",
        e,
      );
    }
  }
}

const hls = new Hls();

export default hls;
export { Hls };

import { RButton } from "@ritual/essentials-for-react";
import styled from "styled-components";
import { navigate } from "../../services/navigation";
import metrics from "../../utils/metrics";
import ScrollableOverflow from "../ScrollableOverflow";

const TabGroupWrapper = styled.div`
  display: block;
  padding: 0 0px var(--between-components-modules-spacing-2, 32px) 0px;

  @media (min-width: 993px) {
    padding-top: var(--between-components-modules-spacing-1, 16px);
  }
`;

const ScrollableWrapper = styled(ScrollableOverflow)`
  padding: 2px var(--grid-margin-xs);

  @media (min-width: 993px) {
    padding: 2px var(--grid-margin-m);
  }

  .scrollable-overflow-draggable-content {
    gap: var(--Between-Components-Only-spacing-0_5, 8px);
  }
`;

export type Link = {
  contentful_id: string;
  text: string;
  backArrow?: boolean;
  to?: {
    slug: string;
    contentful_id: string;
    defaultContent: {
      contentful_id: string;
    };
  };
};

type Props = {
  links: Link[];
  activePlpId: string;
};

type TabGroupProps = <T>(props: Props) => JSX.Element | null;

const TabGroup: TabGroupProps = ({ links, activePlpId }) => {
  const navigateFn = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: any,
  ) => {
    e.preventDefault();

    metrics.track("Secondary Nav Clicked", {
      location: "Product List Page Tab Group",
      title: link.text,
      ...link.metrics,
    });

    if (link?.to?.slug) {
      navigate(`/${link.to.slug}`);
    }
  };

  // Check if at least 1 link has a .to property
  const validLinks = links.filter((link) => link.to);

  return (
    <TabGroupWrapper>
      {validLinks.length > 0 && (
        <ScrollableWrapper>
          {links.map((link) => {
            return (
              <a
                key={link.contentful_id}
                title={link.text}
                href={`/${link?.to?.slug}`}
                onClick={(e) => navigateFn(e, link)}
              >
                <RButton
                  buttonClass={`tab typography-body3 font-circular ${
                    link?.to?.defaultContent?.contentful_id === activePlpId
                      ? "active"
                      : ""
                  }`}
                  backArrow={link.backArrow}
                >
                  {link.text}
                </RButton>
              </a>
            );
          })}
        </ScrollableWrapper>
      )}
    </TabGroupWrapper>
  );
};

export default TabGroup;

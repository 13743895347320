import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";
import { isEmpty } from "lodash";
import { getPromotionDetails } from "../../utils/promotion";
import intlService from "../../services/intl";

const useSeoPricing = (price) => {
  const sitewidePromotion = useSelector(promotionSelectors.siteWidePromotion);
  const amount = price?.toString();

  if (isEmpty(sitewidePromotion)) {
    return { amount, discountAmount: null, priceValidUntil: null };
  }

  const { discountPrice, promotion } = getPromotionDetails(
    sitewidePromotion,
    price,
  );
  const formattedDiscountPrice = intlService
    .formatCurrency(discountPrice, { round: true })
    .slice(1);
  const priceValidUntil = promotion.expiresAt
    ? new Date(promotion.expiresAt).toISOString()
    : null;

  return {
    amount: price?.toString(),
    discountAmount: formattedDiscountPrice.toString(),
    priceValidUntil,
  };
};

// Non-hooks version
export const seoPricing = (price, sitewidePromotion) => {
  const amount = price?.toString();

  if (isEmpty(sitewidePromotion)) {
    return { amount, discountAmount: null, priceValidUntil: null };
  }

  const { discountPrice, promotion } = getPromotionDetails(
    sitewidePromotion,
    price,
  );
  const formattedDiscountPrice = intlService
    .formatCurrency(discountPrice, { round: true })
    .slice(1);
  const priceValidUntil = promotion.expiresAt
    ? new Date(promotion.expiresAt).toISOString()
    : null;

  return {
    amount: price?.toString(),
    discountAmount: formattedDiscountPrice.toString(),
    priceValidUntil,
  };
};

export default useSeoPricing;

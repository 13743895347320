import useVariation from "../useVariation";

type UseContentfulVariation = <T>(
  flag: string,
  defaultContent: T,
  contentVariations: T[] | null,
) => T;

const useContentfulVariation: UseContentfulVariation = (
  flag,
  defaultContent,
  contentVariations,
) => {
  const variation = (useVariation as any)(flag, 0);

  if (
    !variation ||
    !flag ||
    !contentVariations ||
    !contentVariations[variation - 1]
  ) {
    return defaultContent;
  }
  return contentVariations[variation - 1];
};

export default useContentfulVariation;

import styled from "styled-components";
import GridContainer from "../grid/GridContainer";
import GridRow from "../grid/GridRow";
import GridColumn from "../grid/GridColumn";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const SeoBlockWrapper = styled.div`
  padding: var(--spacing-3) 0;
  background: var(--warm-20);

  .r-grid-container {
    grid-row-gap: var(--spacing-2);

    .r-grid-row:first-child {
      grid-row-gap: 0;
    }
  }

  .headline {
    color: var(--indigo-blue);
  }

  .body-copy {
    color: var(--indigo-blue);
  }

  .optional-copy {
    color: var(--indigo-blue-80);
    font-weight: 450 !important;
    margin-top: var(--spacing-1);
  }

  i {
    font-family: var(--font-dutch);
    font-weight: 450;
  }

  h1,
  p {
    position: relative;
    z-index: 10;
  }

  @media (min-width: 768px) {
    padding: var(--spacing-8) var(--spacing-1_5);
  }

  @media (min-width: 993px) {
    .optional-copy {
      margin-top: var(--spacing-2);
    }
  }
`;

type SeoBlockProps = {
  headline?: {
    raw: string;
  };
  description?: {
    raw: string;
  };
  footnote?: string;
};

const SEOBlock = ({ headline, description, footnote }: SeoBlockProps) => {
  if (!headline) return null;

  const parsedHeadline = headline?.raw
    ? documentToHtmlString(JSON.parse(headline.raw))
    : null;
  const parsedDescription = description?.raw
    ? documentToHtmlString(JSON.parse(description.raw))
    : null;

  return (
    <SeoBlockWrapper>
      <GridContainer>
        <GridRow>
          {/* Headline w/ offsets */}
          <GridColumn
            xs={{ size: "0" }}
            s={{ size: "0" }}
            m={{ size: 1 }}
          ></GridColumn>
          <GridColumn xs={{ size: 5 }} s={{ size: 7 }} m={{ size: 8 }}>
            {parsedHeadline && (
              <h1
                className="headline typography-headline1 typography-medium"
                dangerouslySetInnerHTML={{
                  __html: parsedHeadline,
                }}
              ></h1>
            )}
          </GridColumn>
          <GridColumn
            xs={{ size: 1 }}
            s={{ size: 5 }}
            m={{ size: 4 }}
          ></GridColumn>
        </GridRow>
        <GridRow>
          {/* Body copy w/ offsets */}
          <GridColumn
            xs={{ size: 1 }}
            s={{ size: 1 }}
            m={{ size: 5 }}
          ></GridColumn>
          <GridColumn xs={{ size: 5 }} s={{ size: 11 }} m={{ size: 6 }}>
            {parsedDescription && (
              <span
                className="body-copy typography-body1"
                dangerouslySetInnerHTML={{
                  __html: parsedDescription,
                }}
              ></span>
            )}
            {footnote && (
              <p className="optional-copy typography-caption">{footnote}</p>
            )}
          </GridColumn>
          <GridColumn
            xs={{ size: "0" }}
            s={{ size: "0" }}
            m={{ size: 1 }}
          ></GridColumn>
        </GridRow>
      </GridContainer>
    </SeoBlockWrapper>
  );
};

export default SEOBlock;

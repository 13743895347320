import { useCallback } from "react";
import metrics from "../../utils/metrics";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import { useSelector } from "react-redux";
import { productOfferForSlug } from "../../store/product-offer/selectors";
import ProductOffer from "../../store/product-offer/model";

type ListItem = { productSku: string } | { productOfferSlug: string };

const propertiesForProductOffer = (state: any, productOfferSlug: string) => {
  const productOffer: ProductOffer = productOfferForSlug(
    state,
    productOfferSlug,
  );

  if (!productOffer) return null;

  return {
    category: "Bundle",
    currency: productOffer.currency(state),
    image_url: productOffer.imageUrl,
    name: productOffer.name,
    price: productOffer.initialAmount(state) / 100,
    product_id: productOffer.id,
    quantity: 1,
    sku: productOffer.slug,
  };
};

const propertiesFound = Boolean;
const addPosition = (properties: any, index: number) => ({
  ...properties,
  position: index + 1,
});
const useListItemProperties = (listItems: ListItem[]) =>
  useSelector((state) =>
    listItems
      .map((listItem) =>
        "productSku" in listItem
          ? getPropertiesForProductSku(listItem.productSku)
          : propertiesForProductOffer(state, listItem.productOfferSlug),
      )
      .filter(propertiesFound)
      .map(addPosition),
  );

/**
 * The returned memoized callback has internal bugs that prevent is from actually being memoized.
 * Assume the callback is different on every render.
 */
const useProductListViewed = (category: string, listItems: ListItem[]) => {
  const listItemProperties = useListItemProperties(listItems);
  return useCallback(() => {
    metrics.track(
      "Product List Viewed",
      {
        category,
        products: listItemProperties,
      },
      { addTikTokEventId: true },
    );
  }, [category, listItemProperties]);
};

export default useProductListViewed;

import { analytics } from "../utils/analytics";

class MuxMonitor {
  started = false;
  initTime = Date.now();
  queue = [];
  muxInstance = null;

  init() {
    if (!this.started) {
      this.started = true;
      this.executeAfterPageLoad(async () => {
        const chunk = await import("mux-embed");
        this.muxInstance = chunk.default;
        this.emptyQueue();
      });
    }
  }

  pushEvent(data) {
    if (!this.muxInstance) {
      this.queue.push(data);
    } else this.executeEvent(data);
  }

  emptyQueue() {
    this.queue.forEach((event) => this.executeEvent(event));
    this.queue = [];
  }

  getUser() {
    return analytics.getStoredUserInfo() || {};
  }

  executeEvent(data) {
    const user = this.getUser();

    if (this.muxInstance && data.ref) {
      this.muxInstance.monitor(data.ref, {
        debug: false,
        hlsjs: data.hls,
        Hls: data.Hls,
        data: {
          env_key: process.env.GATSBY_MUX_ENV_KEY, // required

          // Metadata fields
          player_name: "gatsby_magic_video",
          video_title: data.title,
          player_init_time: this.initTime,
          viewer_user_id: user.anonymousId,
        },
      });
    }
  }

  executeAfterPageLoad(func) {
    if (document.readyState === "complete") func();
    else if (window.addEventListener)
      window.addEventListener("load", func, false);
    else if (window.attachEvent) window.attachEvent("onload", func);
  }
}

const muxMonitor = new MuxMonitor();

export default muxMonitor;
export { MuxMonitor };

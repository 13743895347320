interface LinkTo {
  contentful_id: string;
  slug: string;
  defaultContent?: {
    contentful_id: string;
  };
}

interface Link {
  contentful_id: string;
  text: string;
  to?: LinkTo;
  backArrow?: boolean;
  metrics: {
    [key: string]: string | undefined;
  };
}

interface NavigationPanel {
  contentful_id: string;
  title: string;
}

interface LinkSet {
  contentful_id: string;
  title: string;
  links: Link[];
  navigationpanel?: NavigationPanel[];
}

interface ParentPlp {
  contentful_id: string;
  slug: string;
  defaultContent: {
    contentful_id: string;
    title: string;
  };
}

interface PlpNavigationParams {
  contentful_id: string;
  linkSets: LinkSet[];
  parentPlp?: ParentPlp;
}

interface PlpNavigationReturn {
  activePanel?: NavigationPanel;
  links: Link[];
  isPrimaryPage: boolean;
}

const usePlpNavigation = (
  topLevelId: string,
  { contentful_id, linkSets, parentPlp }: PlpNavigationParams,
  slug: string,
): PlpNavigationReturn => {
  const activePanel = linkSets?.[0]?.navigationpanel?.[0];

  // Multiple linkSets means that this is a primary page (L1, i.e. Pregnancy, Daily Health)
  const isPrimaryPage = linkSets && linkSets?.length > 1;

  let links = linkSets ? linkSets[0].links : [];
  if (isPrimaryPage) {
    links = [
      {
        // This is the parent link for primary page (L1)
        contentful_id: contentful_id,
        text: `All ${activePanel?.title}`,
        to: {
          contentful_id: contentful_id,
          slug: slug,
          defaultContent: {
            contentful_id: topLevelId, // Used to determine active state of button
          },
        },
        metrics: {
          destination: `All ${activePanel?.title}`,
          destinationUrl: slug,
          panelId: activePanel?.contentful_id,
          panelName: activePanel?.title,
          linkId: contentful_id,
        },
      },
      ...linkSets.map((linkSet) => {
        const link = linkSet.links[0]!;
        const { to } = linkSet.links[0];
        return {
          contentful_id: to?.defaultContent?.contentful_id,
          text: linkSet.title,
          to: to,
          metrics: {
            destination: linkSet.title,
            destinationUrl: to.slug,
            panelId: activePanel?.contentful_id,
            panelName: activePanel?.title,
            linkSetId: linkSet?.contentful_id,
            linkSetName: linkSet?.title,
            linkId: link?.contentful_id,
          },
        };
      }),
    ];
  } else {
    if (parentPlp) {
      links = [
        {
          // This is the parent link for secondary page (L2)
          contentful_id: parentPlp?.contentful_id,
          text: `All ${parentPlp?.defaultContent?.title}`,
          backArrow: true,
          to: {
            contentful_id: parentPlp?.defaultContent?.contentful_id, // Used to determine active state of button
            slug: parentPlp?.slug,
          },
          metrics: {
            destination: `All ${parentPlp?.defaultContent?.title}`,
            destinationUrl: parentPlp?.slug,
            panelId: activePanel?.contentful_id,
            panelName: activePanel?.title,
            linkId: parentPlp?.defaultContent?.contentful_id,
          },
        },
        ...(links
          .map((link) => {
            if (link?.text === "Shop All") return null;
            return {
              ...link,
              metrics: {
                destination: link.text,
                destinationUrl: link.to?.slug,
                panelId: activePanel?.contentful_id,
                panelName: activePanel?.title,
                linkSetId: linkSets[0]?.contentful_id,
                linkSetName: linkSets[0]?.title,
                linkId: link.contentful_id,
              },
            };
          })
          .filter(Boolean) as Link[]),
      ];
    }
  }

  return { activePanel, links, isPrimaryPage };
};

export default usePlpNavigation;

import Helmet from "react-helmet";
import { useSelector } from "react-redux";

// Utils
import config from "../../utils/siteConfig";
import { seoPricing } from "../../hooks/seo/useSeoPricing";
import {
  getPlanForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import promotionSelectors from "../../store/promotion/selectors";

// Components
import GeneralTags from "./GeneralTags";

const ListPageSEO = ({ seoProps }) => {
  const { path, title, description, items, image } = seoProps;

  const sitewidePromotion = useSelector(promotionSelectors.siteWidePromotion);

  const pageUrl = `${config.siteUrl}/${path}`;
  const pageTitle = `Shop ${title} | ${config.siteTitle}`;
  const imgUrl = image || config.siteUrl + config.shareImage;

  let meta = [
    { name: "image", content: imgUrl },
    { name: "title", content: pageTitle },
    { property: "og:image", content: imgUrl },
    { name: "twitter:image", content: imgUrl },
  ];

  let breadcrumbListSchema = [
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": config.siteUrl,
            name: "Home",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": pageUrl,
            name: pageTitle,
          },
        },
      ],
    },
  ];

  const allProductSkus = items.flatMap((item) => {
    // Skip Ed Blocks and Bundle Product Cards
    if (item.type === "Editorial Block") return null;
    if (item.content?.products) return null;

    return item.content?.sku;
  });
  const skus = allProductSkus.filter(Boolean);
  const productAttributes = skus
    .map((sku) => {
      const productPlan = getPlanForProductSku(sku);
      return productPlan && getProductAttributes(productPlan.id);
    })
    .filter(Boolean);

  const renderProductSchemas = () => {
    return productAttributes.map((productAttributes) => {
      const { amount, discountAmount, priceValidUntil } = seoPricing(
        productAttributes.price,
        sitewidePromotion,
      );
      const productPageUrl = `${config.siteUrl}/products/${productAttributes.slug}`;

      const hasDiscount = !!discountAmount;

      const stockStatus = items.find(
        (item) => item.content?.sku === productAttributes.sku,
      )?.content?.stockStatus;

      const availability = (() => {
        switch (stockStatus) {
          case "preorder":
            return "http://schema.org/PreOrder";
          case "out of stock":
            return "http://schema.org/OutOfStock";
          case "in stock":
          default:
            return "http://schema.org/InStock";
        }
      })();

      let productSchema = {
        "@context": "http://schema.org",
        "@type": "Product",
        name: productAttributes.name,
        description: productAttributes.description,
        brand: productAttributes.brand,
        image: {
          "@type": "ImageObject",
          url: productAttributes.image_url,
        },
        sku: productAttributes.productId,
        mpn: productAttributes.productId,
        gtin: productAttributes.gtin ?? undefined,
        offers: {
          "@type": "Offer",
          availability,
          url: productPageUrl,
          priceSpecification: [
            {
              "@type": "UnitPriceSpecification",
              price: amount,
              priceCurrency: productAttributes.currency,
              priceType: "MSRP",
            },
            hasDiscount && {
              "@type": "UnitPriceSpecification",
              price: discountAmount,
              priceCurrency: productAttributes.currency,
              priceType: "SalePrice",
              validThrough: priceValidUntil
                ? new Date(priceValidUntil).toISOString()
                : undefined,
            },
          ],
          shippingDetails: {
            "@type": "OfferShippingDetails",
            shippingLabel: "Free Shipping",
            deliveryTime: {
              "@type": "ShippingDeliveryTime",
              businessDays: {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                  "https://schema.org/Monday",
                  "https://schema.org/Tuesday",
                  "https://schema.org/Wednesday",
                  "https://schema.org/Thursday",
                  "https://schema.org/Friday",
                ],
              },
              handlingTime: {
                "@type": "QuantitativeValue",
                minValue: 0,
                maxValue: 1,
                unitCode: "d",
              },
              transitTime: {
                "@type": "QuantitativeValue",
                minValue: 1,
                maxValue: 7,
                unitCode: "d",
              },
            },
            shippingDestination: {
              "@type": "DefinedRegion",
            },
            shippingRate: {
              "@type": "MonetaryAmount",
              currency: productAttributes.currency,
              value: 0,
            },
          },
          hasMerchantReturnPolicy: {
            "@type": "MerchantReturnPolicy",
            merchantReturnLink: config.returnPolicy,
            applicableCountry: "US",
            description: "30 Money-Back Guarantee without Product Return",
            merchantReturnDays: 30,
            returnFees: 0,
          },
        },
      };

      return productSchema;
    });
  };

  return (
    <div>
      <GeneralTags
        pagePath={path}
        title={pageTitle}
        description={description}
        image={image}
      />
      <Helmet meta={meta}>
        <script type="application/ld+json">
          {JSON.stringify([
            ...breadcrumbListSchema,
            ...renderProductSchemas(productAttributes),
          ])}
        </script>
      </Helmet>
    </div>
  );
};

export default ListPageSEO;

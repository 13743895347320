import styled from "styled-components";
import MagicVideo from "../MagicVideo";
import { useEffect, useRef, useState } from "react";
import metrics from "../../utils/metrics";
import RitualButton from "../global/RitualButton";

const EditorialBlockVideoWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-color: lightgrey;
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: var(--between-components-modules-spacing-1, 16px);
  bottom: var(--between-components-modules-spacing-1, 16px);
  gap: var(--Between-Components-Only-spacing-0_5, 8px);
`;

type VideoContent = {
  captions?: any;
  description?: {
    description: string;
  };
  stream: {
    videoUpload: {
      playbackId: string;
    };
  };
  title: string;
  videoLength?: number;
  hasAudio?: boolean;
};

type Content = {
  videoContent: any;
};

type Props = {
  item: {
    content: Content;
  };
};

type EditorialBlockVideoProps = <T>(props: Props) => JSX.Element | null;

const EditorialBlockVideo: EditorialBlockVideoProps = ({ item }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);

  const onClickPlayHandler = () => {
    if (!videoRef.current) return;

    if (playing) {
      metrics.track("Video Playback Paused", {
        content_asset_id:
          item?.content?.videoContent?.stream?.videoUpload?.playbackId,
        title: item?.content?.videoContent?.title,
      });

      videoRef.current.pause();
    } else {
      metrics.track("Video Playback Started", {
        content_asset_id:
          item?.content?.videoContent?.stream?.videoUpload?.playbackId,
        title: item?.content?.videoContent?.title,
      });

      videoRef.current.play();
    }

    setPlaying(!playing);
  };

  const onClickMuteHandler = () => {
    if (!videoRef.current) return;

    if (muted) {
      videoRef.current.muted = false;
    } else {
      videoRef.current.muted = true;
    }

    setMuted(!muted);
  };

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)",
    );

    if (prefersReducedMotion.matches) {
      setPlaying(false);
    }
  }, []);

  const videoProps = {
    muted: muted,
    playsInline: true,
    autoPlay: playing,
    preload: "auto",
    controls: false,
    loop: true,
  };

  const videoStyles = {
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  if (!item.content.videoContent) return null;

  const { videoContent } = item.content as Content;
  const { hasAudio } = videoContent as VideoContent;

  return (
    <EditorialBlockVideoWrapper>
      <ButtonWrapper>
        {hasAudio && (
          <RitualButton className="round-icon" onClick={onClickMuteHandler}>
            {muted ? (
              <img
                alt="Unmute Video"
                src="https://assets.ritual.com/icons/mute-small.svg"
                slot="icon"
              />
            ) : (
              <img
                alt="Mute Video"
                src="https://assets.ritual.com/icons/unmute-small.svg"
                slot="icon"
              />
            )}
          </RitualButton>
        )}
        <RitualButton className="round-icon" onClick={onClickPlayHandler}>
          {playing ? (
            <img
              alt="Pause Video"
              src="https://assets.ritual.com/icons/pause-small.svg"
              slot="icon"
            />
          ) : (
            <img
              alt="Play Video"
              src="https://assets.ritual.com/icons/play-small.svg"
              slot="icon"
            />
          )}
        </RitualButton>
      </ButtonWrapper>
      <MagicVideo
        ref={videoRef}
        {...videoProps}
        {...videoContent}
        videoElementStyle={videoStyles}
      />
    </EditorialBlockVideoWrapper>
  );
};

export default EditorialBlockVideo;
